<template>
  <div>
    <div style="width: 100%">
      <img style="width: 100%" src="../solution/img/顶部背景.png" alt="" />
    </div>
    <div class="bottomCss">
      <div class="xiangqing">
        <div class="title">储能解决方案</div>
        <div class="jieshao">
          公司结合自身在新能源领域十多年的技术基础及行业应用，为客户提供储能整体解决方案，包括发电侧储能解决方案、电网侧储能解决方案以及用户侧储能解决方案。为用户提供项目可研、方案、设计、设备选型采购、项目管理及施工、后期运维全生命周期的系统服务。
        </div>
        <div class="xiaobiaoqian9">
          <img src="./img/小标签.png" alt="" />
          <span
            style="
              font-size: 30px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #000000;
            "
            >发电侧储能解决方案</span
          >
        </div>
        <div class="jieshao">
          发电侧储能解决方案讲储能技能与再生能源发电技术相结合，解决峰值负荷供电的问题，从而使光伏发电、风力发电等新能源发电技术更具实用价值。
        </div>
        <div>
          <img
            style="width: 1186px; height: 416px; margin-top: 36px"
            src="./img/储能1.png"
            alt=""
          />
        </div>
        <div class="xiaobiaoqian9">
          <img src="./img/小标签.png" alt="" />
          <span
            style="
              font-size: 30px;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #000000;
            "
            >电网侧储能解决方案</span
          >
        </div>
        <div class="jieshao">
          随着只能电网的建设，电力系统中储能应用的必要性日益增加。储能可以直接参与各级电网调度中心的直接调度，满足区域电网调峰、调频、调压、应急响应、黑启动等应用需求，为电网的安全平稳运行提供保障。
        </div>
        <div>
          <img
            style="width: 1186px; margin-top: 36px; margin-bottom: 64px"
            src="./img/储能2.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataBox: [
          {
            text: "实时数据采集与处理（采集数据，滤除无效数据，并同时告警）",
          },
          {
            text: "运营监视与告警（监视各分布式电源运行状态，微网系统和各运行设备的实际运行工况信息）",
          },
          {
            text: "控制与调节（可实现预定义控制序列实现可靠闭锁，以改变输电线路的功率）",
          },
          {
            text: "统计与分析（实行自动判断优先级，实现平衡分析，量测平衡率的自动统计）",
          },
          {
            text: "自动发电控制（实现电压调整和频率控制，能量变换装置的功率输出的调节，储能装置的管理）",
          },
          {
            text: "状态估计（实时地给出公共连接点电网参数，各母线电压，各线路、变压器等支路的潮流，各母线的负荷和各微电源的输出特性参数）",
          },
          {
            text: "能量供需预测（对微电源出力进行超短期、短期和中长期的预测）",
          },
          {
            text: "调度员潮流（可设定操作，改变运行方式）",
          },
        ],
    };
  },
};
</script>

<style scoped>
.bottomCss {
  width: 100%;
  height: 100%;
  background: #f2f5f8;
  display: flex;
  justify-content: center;
}
.xiangqing {
  width: 1314px;
  /* height: 1404px; */
  margin-top: 64px;
  margin-bottom: 88px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 38px;
  font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  /* line-height: 0px; */
  margin-top: 54px;
  margin-bottom: 36px;
}
.jieshao {
  font-size: 22px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 38px;
  width: 1186px;
}
.xiaobiaoqian {
  display: flex;
  margin-bottom: 36px;
  margin-top: 48px;
  margin-left: -78.5%;
}
.xiaobiaoqian9 {
  display: flex;
  margin-bottom: 36px;
  margin-top: 48px;
  margin-left: -67%;
}
li::before {
  content: "";
  display: flex;
  width: 9px;
  height: 9px;
  background: #0940fd;
  border-radius: 50%;
  margin: 1%;
}
</style>